<template>
  <div class="revenue-wrap">
    <!-- 时间选择 -->
    <time-select @timeChange="timeChange"></time-select>
    <div class="scroll-container">
        <div class="main-content">
          <!--营收走势图-->
          <revenue-chart :chartParams="chartParams" :timeParams="timeParams"></revenue-chart>
          <!--同步时间-->
          <synchronize-time></synchronize-time>
          <!--营收汇总-->
           <!-- v-if="revenueSummary" -->
          <revenue-summary
            :data="revenueSummary"
            :timeType="timeParams.type"
            :chartParams="chartParams"
            @changeChartDate="changeChartDate"
            >
            </revenue-summary>
            <!--会员类型饼图-->
            <revenue-member-chart></revenue-member-chart>
            <!--收费记录-->
            <revenue-charge-record :timeParams="timeParams"></revenue-charge-record>
        </div>
        <daqi-copyright></daqi-copyright>
    </div>
  </div>

</template>

<script>
import table from '@/api/table'
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'revenue',
  components: {
    'time-select':() => import('@/components/time-select'),
    'revenue-chart':() => import('./revenue-chart'),
    'synchronize-time':() => import('@/components/synchronize-time'),
    'revenue-summary':() => import('./revenue-summary'),
    'revenue-member-chart':() => import('./revenue-member-chart'),
    'revenue-charge-record':() => import('./revenue-charge-record'),
    'daqi-copyright':() => import('@/components/daqi-copyright')
  },
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {
      revenueSummary:null,
      timeParams:{},
      chartParams:{}
    }
  },
  computed: {
	...mapState(['openid','userStatus']),
  },
  watch: {
    timeParams:{
    	handler(newVal,oldVal){
    		if(! newVal){
    			return
    		}
    		if(! ! oldVal && newVal.startTime === oldVal.startTime){
    			return
    		}
    		this.getRevenueSummary(newVal)
    	},
    	deep:true,
    },
    'userStatus.curs_node_id_2':{
        deep:true,
        handler(){
          this.getRevenueSummary(this.timeParams)
        }
    }
  },
  created () {
    this.$store.commit("updateLoadnum",8)
    console.log(localStorage.getItem('sub'))
  },
  mounted () {
    this.$store.commit("updateIsShowTabBar",true)
    this.$store.commit("updateIsShowPickStore",true)
  },
  methods: {
    /**
     * 获取营收汇总数据
     */
    getRevenueSummary(params){
    	table.getRevenueSummary(params).then(res =>{
    		this.revenueSummary = res.data
        console.log(this.revenueSummary)
    	}).catch(err =>{
    		console.log(err.info)
    	})
    },
    /* 时间切换*/
    timeChange(params){
      console.log(params)
    	this.timeParams = params
    },
    /* 图标数据切换*/
    changeChartDate(chartParams){
    	this.chartParams = chartParams
    }
  }
}
</script>
<style lang="scss" scoped>
    @import "~@/assets/style/public.scss";
  .revenue-wrap {
    @extend %full;
  }
  .scroll-container{
      position:absolute;
      top:90px;
      left:0;
      right:0;
      bottom:0;
      overflow-y:auto;
      overflow-x:hidden;
      -webkit-overflow-scrolling:touch;
      overflow-scrolling:touch;
      padding-bottom:10px;
      margin-bottom:-10px;
  }
  .main-content{
      min-height:calc(100% - 101px);
  }
</style>
